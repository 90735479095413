import { render, staticRenderFns } from "./create_company.vue?vue&type=template&id=a9e76ea8&scoped=true&"
import script from "./create_company.vue?vue&type=script&lang=js&"
export * from "./create_company.vue?vue&type=script&lang=js&"
import style0 from "./create_company.vue?vue&type=style&index=0&id=a9e76ea8&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "a9e76ea8",
  null
  
)

export default component.exports