<template>
<div class="create-company">
  <div class="company-from">
    <div class="company-from-box">
      <van-form validate-first class="from" ref="ruleForm">

    <van-field
      class="com-file"
      required
      input-align="right"
      error-message-align="right"
      size="large"
      v-model="from.companyName"
      label="企业名称"
      name="companyName"
      placeholder="请输入企业名称"
      maxlength="20"
      :rules=rules.companyName
    />

    <van-field
      required
      input-align="right"
      error-message-align="right"
      size="large"
      readonly
      clickable
      is-link
      name="companyType"
      :value="from.companyType"
      label="企业类型"
      placeholder="请选择"
      :rules=rules.companyType
      @click="pickerChange('companyType')"
    />
<!--    <van-popup v-model="state.companyType" position="bottom">
      <van-picker
        show-toolbar
        value-key="itemText"
        :columns="company_type"
        @confirm="onConfirm($event,'companyType')"
        @cancel="state.companyType = false"
      />
    </van-popup>-->

    <van-action-sheet
      v-model="state.companyType"
      closeable title="企业类型"
      class="sheet">
      <div class="content">
          <div v-for="(item,index) in company_type" :key="index" class="list" @click="onConfirm(item,'companyType')">
            <div class="text">{{item.itemText}}</div>
            <div class="box"></div>
          </div>
      </div>
    </van-action-sheet>






    <van-field
      required
      input-align="right"
      error-message-align="right"
      size="large"
      is-link
      readonly
      clickable
      name="industryType"
      :value="from.industryType"
      label="所属行业"
      placeholder="请选择"
      :rules=rules.industryType
      @click="pickerChange('industryType')"
    />
    <van-popup v-model="state.industryType" position="bottom" round>
      <van-picker
        class="picker"
        title="所属行业"
        round
        show-toolbar
        value-key="itemText"
        :columns="industry"
        @confirm="onConfirm($event,'industryType')"
        @cancel="state.industryType = false"
      />
    </van-popup>

    <van-field
      required
      input-align="right"
      error-message-align="right"
      size="large"
      is-link
      readonly
      clickable
      name="companyScaleType"
      :value="from.companyScaleType"
      label="企业规模"
      placeholder="请选择"
      :rules=rules.companyScaleType
      @click="pickerChange('companyScaleType')"
    />
    <van-popup v-model="state.companyScaleType" position="bottom" round>
      <van-picker
        title="企业规模"
        class="picker"
        show-toolbar
        value-key="itemText"
        :columns="company_scale"
        @confirm="onConfirm($event,'companyScaleType')"
        @cancel="state.companyScaleType = false"
      />
    </van-popup>


    <van-field
      required
      input-align="right"
      error-message-align="right"
      size="large"
      v-model="from.legalPersonName"
      label="法人代表"
      name="legalPersonName"
      placeholder="请输入法人代表"
      maxlength="10"
      :rules=rules.legalPersonName
    />


    <div class="text">
      <van-field
        required
        input-align="right"
        error-message-align="right"
        size="large"
        label="统一社会信用代码"
        readonly
        label-width="200"
        :border="false"
        class="file-text"
      />
      <van-field
        class="file-val"
        size="large"
        v-model="from.socialCreditCode"
        name="socialCreditCode"
        placeholder="请输入统一社会信用代码"
        maxlength="20"
        :rules=rules.socialCreditCode
      />
    </div>


    <div class="text">
      <van-field
        required
        input-align="right"
        error-message-align="right"
        size="large"
        label="企业地址"
        readonly
        label-width="150"
        :border="false"
        class="file-text"
      />
      <van-field
        class="file-val holder"
        size="large"
        v-model="from.companyAddress"
        name="companyAddress"
        placeholder="请输入您的企业地址"
        maxlength="100"
        type="textarea"
        :rules=rules.companyAddress
      />
    </div>


    <div class="text">
      <van-field
        required
        input-align="right"
        error-message-align="right"
        size="large"
        label="企业简介"
        readonly
        label-width="150"
        :border="false"
        class="file-text"
      />
      <van-field
        class="file-val holder"
        size="large"
        v-model="from.companyBrief"
        name="companyBrief"
        placeholder="请输入企业简介"
        maxlength="300"
        type="textarea"
        :rules=rules.companyBrief
      />
    </div>




    <van-field
      required
      input-align="right"
      error-message-align="right"
      size="large"
      v-model="from.contactPerson"
      label="联系人"
      name="contactPerson"
      placeholder="请输入联系人"
      maxlength="10"
      :rules=rules.contactPerson
    />

    <van-field
      required
      input-align="right"
      error-message-align="right"
      size="large"
      v-model="from.contactPhone"
      label="联系电话"
      name="contactPhone"
      placeholder="请输入联系电话"
      maxlength="20"
      :rules=rules.contactPhone
    />

  </van-form>
    </div>
  </div>

  <div class="btn">
    <van-button class="btn-left" color="#EA0B06" @click="handleSubmit" block>提交</van-button>
  </div>

</div>
</template>

<script>
import { Toast } from 'vant';
import { mapActions, mapGetters } from 'vuex'
import {
  createCompany,
  getCurrentCompany,
} from "@/api/res-company";
export default {
  name: 'create_company',
  data(){
    const validatorPhone = (rule, value, callback) => {
      if (!/^1[3456789]\d{9}$/.test(value)) {
        callback(new Error("手机号格式不正确!"));
      } else {
        callback();
      }
    };
    return{
      state:{
        companyType:false,
        industryType:false,
        companyScaleType:false,
      },
      company_info:{
        companyType:'',
        industryType:'',
        companyScaleType:'',
      },
      company_type:"",
      industry:'',
      company_scale:'',
      from: {
        companyName: "",
        companyType: undefined,
        industryType: undefined,
        companyScaleType: undefined,
        legalPersonName: "",
        socialCreditCode: "",
        companyAddress: "",
        companyBrief: "",
        contactPerson: "",
        contactPhone: "",
      },
      rules: {
        companyName: [
          {
            required: true,
            trigger: "onBlur",
          },
        ],
        companyType: [
          {
            required: true,
            trigger: "onChange",
          },
        ],
        industryType: [
          {
            required: true,
            trigger: "onChange",
          },
        ],
        companyScaleType: [
          {
            required: true,
            trigger: "onChange",
          },
        ],
        legalPersonName: [
          {
            required: true,
            trigger: "onBlur",
          },
        ],
        socialCreditCode: [
          {
            required: true,
            trigger: "onBlur",
          },
        ],
        companyAddress: [
          {
            required: true,
            trigger: "onBlur",
          },
        ],
        companyBrief: [
          {
            required: true,
            trigger: "onBlur",
          },
        ],
        contactPerson: [
          {
            required: true,
            trigger: "onBlur",
          },
        ],
        contactPhone: [
          { required: true, trigger: "onBlur" },
          { pattern: /^1[3456789]\d{9}$/, message: '手机号码格式错误！',trigger: "onBlur"},
        ],
      },
    }
  },
  computed:{
    ...mapGetters(['dict']),
    ...mapGetters(["userInfo"]),
  },
  async mounted () {

    document.title = '创建新企业'
    await this.LoadDictData(['COMPANY_TYPE'])
    await this.LoadDictData(['INDUSTRY'])
    await this.LoadDictData(['COMPANY_SCALE_TYPE'])
    this.company_type=this.dict['COMPANY_TYPE']
    this.industry=this.dict['INDUSTRY']
    this.company_scale=this.dict['COMPANY_SCALE_TYPE']
    this.from.contactPerson=this.userInfo.realName
    this.from.contactPhone=this.userInfo.phone

  },

  methods: {
    ...mapActions(['LoadDictData']),

    onConfirm(value,name) {
      this.from[name]=value.itemText
      this.company_info[name]=value.itemValue
      this.state[name]=false
    },


    onSelect(value,name){
      console.log(value,name)
    },

    pickerChange(name){
      this.state[name]=true
      console.log(this.state,'this.state ===pickerChange')
    },

    handleSubmit(){
      this.$refs.ruleForm.validate().then(()=>{

        for(let i in this.from){
          let exist=this.company_info.hasOwnProperty(i)
          if(!exist){
            this.company_info[i]=this.from[i]
          }
        }


        Toast.loading({
          message: '创建中',
          forbidClick: true,
        });
        this.company_info.isH5Create=true
        createCompany(this.company_info).then((res)=>{
          if(res){
            Toast(res)
          }else {
            Toast.clear()
            Toast('提交成功');
            this.$router.push('/verified')
          }
        })


      })



    }

  }
}
</script>

<style scoped lang="scss">
.create-company{
  width: 100vw;
  background: #F6F6F6;
  >.company-from{
    width: 100vw;
    padding: 16px 16px 0 16px;

    .company-from-box{
      background: #FFFFFF;
      padding: 0 4px;
      border-radius: 4px;
    }

    .picker{
      ::v-deep{
        .van-picker__toolbar{
          .van-picker__title{
            font-size: 16px;
            font-weight: bold;
            color: #000000;
          }
        }
      }
    }


    .sheet{
      .content{
        >.list{
          margin: 0 9px 0 23px;
          >.text{
            font-size: 16px;
            //font-weight: 500;
            color: #000000;
            height: 51px;
            line-height: 51px;
            margin: 0 9px 0 23px;
            box-sizing: border-box;
          }
          >.box{
            width: 100%;
            height: 1px;
            background: #EEEEEE;
          }
        }

      }

      ::v-deep{
        .van-action-sheet__header{
          font-size: 16px;
          font-weight: bold;
          color: #000000;
        }

      }
    }


    ::v-deep{
      .van-cell__title{
        font-size: 16px;
        //font-weight: 500;
        color: #333333;
      }
      .van-cell__value{
        font-size: 16px;
        //font-weight: 500;
        color: #999999;
      }
      .van-cell{
        padding-right: 8px;
        &::after{
        position: absolute;
        box-sizing: border-box;
        content: ' ';
        pointer-events: none;
        right: 8px;
        bottom: 0;
        left: 8px;
        border-bottom: 1px solid #ebedf0;
        }
      }
    }

    .text{
      background: #FFFFFF;
      &::v-deep{
        .van-cell{
          background: #F6F6F6;
        }
      }
    }
  }

  .file-text{
    background: #FFFFFF !important;
  }
  .file-val{
    margin: 0 8px 0 8px;
    width: auto;
  }

  .holder{
    ::v-deep{
      .van-cell__value{
        font-size: 12px !important;
        //font-weight: 500;
        color: #999999;
      }
    }
  }

  >.btn{
    width: 100vw;
    padding: 16px 16px 48px 16px;
  }
}
</style>
